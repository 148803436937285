import React from "react";
import "./Home.css";
import Product from "./Product";

import fakeNewsSplash from './img/fake-news-splash.png'

import {products as rows} from './data/products'

const SampleProduct = ({data}) => {
   const {slug, img, price, title, rating} = data;
   return (<Product
    id={slug}
    title={title}
    price={price}
    rating={Math.floor(Math.random()*5) + 1}
    image={`/img/products/${img}`}
  />);
}

function Home() {
  return (
    <div className="home">
      <div className="home__container">
        <div style={{position: 'absolute', zIndex:1, display:'none'}}>
         <iframe width="560" height="315" src="https://www.youtube.com/embed/OoM5yx1IVFY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
         </div>
        <img
          className="home__image"
          src={fakeNewsSplash}
          alt=""
        />

        <div className="splash">Inspired by <h2>Avito.ru</h2> and brave <a href="https://nemyrych.livejournal.com/81319.html">Russian valuables-liberation Army</a></div>
        
      <a href="https://www.youtube.com/watch?v=OoM5yx1IVFY" className="video__spot"></a>

        {rows.map(r => <div className="home__row">{
          r.map( p  => <SampleProduct data={p} />)
        }</div>)}
      </div>
    </div>
  );
}

export default Home;
