export const products = [
    [
    
    {
        slug: 'tvset',
        title: 'Looted and delivered - not by amazon Drone, but by real ruZZian tank for you!',
        description: '',
        link: undefined,
        price: 63.22,
        img: 'loo.jpg'
    },
    {
        slug: 'tv',
        title: 'TV - as proverb says "You can impress a ruzzian soldier with a flushy-ceramic toilet, but his soul belong to television"',
        description: '',
        link: undefined,
        price: 13.25,
        img: 'tv.jpg'
    },

   ],
   [
    {
        slug: 'loo',
        title: 'Looty-charms(c) - something special for his loved one!',
        description: '',
        link: undefined,
        price: 3.25,
        img: 'jewelery.jpg'
    },
    {
        slug: 'washing machine',
        title: 'The most effective tool to catch brave ruZZian soldier. Developed by MI6 and CIA, more effective than drones and stingers',
        description: '',
        link: undefined,
        price: 25.99,
        img: 'capture-russian.jpg'
    },
    {
        slug: 'tv',
        title: 'Whole bundle of looted goods (As seen on TV)',
        description: '',
        link: undefined,
        price: 13.25,
        img: 'loot1.jpg'
    }
   ],
   [
   {
    slug: 'furniture',
    title: 'IKEA can quit Russia. Dont waste your time for assembly',
    description: '',
    link: undefined,
    price: 25.99,
    img: 'furniture.jpg'
},
{
    slug: 'tv',
    title: 'Your meal should taste fabolous, prepared on this pan with russian gas',
    description: '',
    link: undefined,
    price: 13.25,
    img: 'pan.jpg'
},
{
    slug: 'tv',
    title: 'We have a long history and tradition in sustainable looting. The best items are picked by professional looters. ',
    description: '',
    link: undefined,
    price: '-',
    img: 'history.jpg'
}
],
   [
    {
        slug: 'tv',
        title: 'Brave ruzzian soldiers can provide same-day loot experience. Order fresh loot for you directly from Ukraine today. Or any other country of Europe next-year with premium delivery',
        description: '',
        link: undefined,
        price: 99.99,
        img: 'post.png'
    }
   ]
]